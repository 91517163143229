import React from "react"
import Layout from "./Layout"
import * as data from "../assets/js/data"

const Topic = ({ id, children }) => {
  const copy = data.topicCopy[id]
  const links = data.topicLinks[id]
  const videoUrl = data.topicVideoUrls[id]

  return (
    <Layout>
      <section className={`topic__banner o__${id}`}>
        <h1 className="topic__banner-title">{copy.title}</h1>
      </section>
      <div className="topic">
        <div className="topic__item o__highlight">{children}</div>
        {links && (
          <div className="topic__item">
            <ul className="topic__link-list">
              {links.map(({ text, url }) =>
                url ? (
                  <li key={text + url}>
                    <a
                      className="topic__link"
                      key={url}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {text}
                    </a>
                  </li>
                ) : (
                  <h5 key={text}>{text}</h5>
                )
              )}
            </ul>
          </div>
        )}
      </div>
      {videoUrl && (
        <div className="topic">
          <div className="topic__item o__video">
            <iframe
              title={copy.title}
              width="100%"
              height="100%"
              src={videoUrl}
              allowFullScreen
            />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Topic
