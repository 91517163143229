import React from "react"
import Topic from "../../components/Topic"
import { Link } from "gatsby"

const Government = () => (
  <Topic id="government">
    <p>
      Canada is a very large country with people from many different ethnic
      backgrounds. There are 3 levels of government: Federal, Provincial and
      Municipal. Each level of government is responsible for providing different
      essential services.
    </p>
    <p>
      The federal government is responsible for matters related to your Social
      Insurance Number (SIN); filing of income through Canada Revenue Agency
      (CRA); Immigration, Refugees & Citizenship Canada (IRCC) and many more.
    </p>
    <p>
      Canada has 10 provinces and 3 territories, each with its own provincially
      elected officials. Services managed by the provincial government include
      Health Benefits, Education, Housing and Transportation.
    </p>
    <p>
      Municipal governments are locally elected authorities for a city or town.
      They manage things like{" "}
      <a
        href="https://www.thecanadianencyclopedia.ca/en/article/waste-disposal"
        target="_blank"
        rel="noreferrer"
      >
        waste disposal
      </a>
      , public transit, fire services,{" "}
      <a
        href="https://www.thecanadianencyclopedia.ca/en/article/police"
        target="_blank"
        rel="noreferrer"
      >
        policing
      </a>
      , community centres and{" "}
      <a
        href="https://www.thecanadianencyclopedia.ca/en/article/libraries"
        target="_blank"
        rel="noreferrer"
      >
        libraries
      </a>
      .
    </p>
    <p>
      You need a 9-digit number known as a{" "}
      <strong>Social Insurance Number (SIN)</strong> in order to work in Canada
      and to access government programs and benefits. Your SIN is private and it
      is illegal for anyone else to use it. Click{" "}
      <a
        href="https://www.canada.ca/en/employment-social-development/services/sin/apply.html"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>{" "}
      to learn how to apply for one.{" "}
      <strong>
        As soon as you get your social insurance number, you can apply for
        government benefits. See{" "}
        <Link to="/settling/financial_aid/">Financial Aid</Link> for more
        information.
      </strong>
    </p>
    <p>
      If you do not have a driver&apos;s licence, apply for a{" "}
      <strong>photo ID card</strong> in the province you live in. It is a
      government-issued photo identification to those who do not already have a
      driver&apos;s licence. It allows you to open a bank account and do other
      activities that require official identification easier.
    </p>
    <p>
      <strong>Income Tax and CRA -</strong> You become a{" "}
      <strong>resident of Canada for income tax purposes</strong> when you
      establish significant{" "}
      <a
        href="https://www.canada.ca/en/revenue-agency/services/tax/international-non-residents/information-been-moved/determining-your-residency-status.html"
        target="_blank"
        rel="noreferrer"
      >
        residential ties
      </a>{" "}
      in Canada. You usually establish these ties on the date you arrive in
      Canada. The deadline for filing personal income tax each year is April 30.
      There are community groups that assist newcomers to file tax returns for
      free. It is best to get help for your first return. Use the{" "}
      <a
        href="http://www.cra-arc.gc.ca/volunteer"
        target="_blank"
        rel="noreferrer"
      >
        Free Tax Clinic
      </a>{" "}
      link to find one in your community. You can go to the{" "}
      <Link to="/settling/finance/">Finance</Link> section to learn more and
      also watch the videos for more information.
    </p>
  </Topic>
)

export default Government
